<template>
  <v-row>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :is-loading="areExercisesLoading"
        :statistic-value="exercises.length"
        :icon="icons.mdiWeightLifter"
        title="Exercises"
        color="success"
      ></statistics-card-vertical>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :is-loading="areSessionsLoading"
        :statistic-value="sessions.length"
        :icon="icons.mdiAvTimer"
        title="Sessions"
        color="success"
      ></statistics-card-vertical>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :is-loading="areFitnessAssessmentsLoading"
        :statistic-value="fitnessAssessments.length"
        :icon="icons.mdiDumbbell"
        title="Fitness Assessments"
        color="info"
      ></statistics-card-vertical>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :is-loading="areGolfAssessmentsLoading"
        :statistic-value="golfAssessments.length"
        :icon="icons.mdiGolfTee"
        title="Golf Assessments"
        color="info"
      ></statistics-card-vertical>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiAvTimer, mdiDumbbell, mdiGolfTee, mdiWeightLifter,
} from '@mdi/js'
import StatisticsCardVertical from '@/components/statisticCards/StatisticsCardVertical.vue'
import useExercises from '@/composables/api/exercises'
import useSessions from '@/composables/api/sessions'
import useFitnessAssessments from '@/composables/api/assessments/fitness'
import useGolfAssessments from '@/composables/api/assessments/golf'
import { onMounted } from 'vue'

export default {
  components: {
    StatisticsCardVertical,
  },

  setup() {
    const { exercises, isLoading: areExercisesLoading, fetchExercises } = useExercises()
    const { sessions, isLoading: areSessionsLoading, fetchSessions } = useSessions()
    const { fitnessAssessments, isLoading: areFitnessAssessmentsLoading, fetchFitnessAssessments } = useFitnessAssessments()
    const { golfAssessments, isLoading: areGolfAssessmentsLoading, fetchGolfAssessments } = useGolfAssessments()

    onMounted(() => {
      fetchExercises()
      fetchSessions()
      fetchFitnessAssessments()
      fetchGolfAssessments()
    })

    return {
      exercises,
      areExercisesLoading,

      sessions,
      areSessionsLoading,

      fitnessAssessments,
      areFitnessAssessmentsLoading,

      golfAssessments,
      areGolfAssessmentsLoading,

      icons: {
        mdiAvTimer,
        mdiDumbbell,
        mdiGolfTee,
        mdiWeightLifter,
      },
    }
  },
}
</script>
